import './Default.css';
import { useState } from 'react';

export default function Imprint({ handleShow }) {
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const sendMail = async () => {
        if (name !== "" && mail !== "" && message !== "") {
            setError("");
            await fetch("/send.js", {
                method: "POST",
                body: JSON.stringify({ name, mail, message }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.id) {
                        alert(`Thank you for your interest ${name}! We will get back to you soon!`);
                        setName("");
                        setMail("");
                    } else {
                        alert("Apologies! Please try again.");
                    }
                })
                .catch((err) => {
                    alert("Ooops! unfortunately some error has occurred.");
                });
        } else {
            setError('Every Inputbox must be filled.')
        }
    }

    return (
        <>
            <div className="ModalBackground" onClick={() => handleShow(false)} />
            <div className='Modal-Content'>
                <h1 className='ModalTitle'>Contact Me:</h1>
                <h3 className='ModalMail'>office@christianschwer.com</h3>
                <h4 className='ModalInputTitle'>Name</h4>
                <input className='ModalInput' type="text" placeholder="Max Mustermann" value={name} onChange={(e) => setName(e.target.value)}/>
                <h4 className='ModalInputTitle'>E-Mail</h4>
                <input className='ModalInput' type="text" placeholder="max@mustermann.com" value={mail} onChange={(e) => setMail(e.target.value)}/>
                <h4 className='ModalInputTitle'>Your Message</h4>
                <textarea className='ModalInput' placeholder="Text..." value={message} onChange={(e) => setMessage(e.target.value)}/>
                <button className='ModalSendButton' onClick={sendMail}>Send</button>
                <p>{error}</p>
            </div>
        </>
    )
}