import { useState } from 'react';
import { Link } from "react-router-dom";
import Modal from './Modal.js'

export default function Privacy() {
  const [show, setShow] = useState(false);

  const handleShow = (value) => {
    setShow(value);
  }

  return (
    <div className='Body'>
      <h1>Privacy</h1>
      <p className='Text'>The protection of your personal data is of particular concern to us. We therefore process your data exclusively on the basis of the statutory provisions (DSGVO, TKG 2003). In this data protection information we inform you about the most important aspects of data processing within the framework of our website. When you visit our website, your IP address, the beginning and end of the session will be recorded for the duration of this session. This is due to technical reasons and thus represents a legitimate interest within the meaning of Art 6 Paragraph 1 lit f DSGVO. Unless otherwise regulated below, we do not process this data further.</p>
      <h3>Contact with us</h3>
      <p className='Text'>If you contact us via the form on the website or by e-mail, your data will be stored for six months for the purpose of processing your enquiry and in the event of follow-up questions. We will not pass on this data without your consent.</p>
      <h3>Your rights</h3>
      <p className='Text'>With regard to your data stored by us, you are entitled to the rights of information, correction, deletion, restriction, data transferability, revocation and objection. If you believe that the processing of your data violates data protection law or your data protection rights have otherwise been violated in any way, you can lodge a complaint with us office@christianschwer.com or the data protection authority.</p>
      <Link to='/' className='BackButton'>Back</Link>
      <div className='ContactMe'>
        <i className="fa-solid fa-envelope fa-2xl"  onClick={() => {setShow(true);}}></i>
        {show && <Modal handleShow={handleShow} />}
      </div>
    </div>
  )
}
