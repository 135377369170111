import './Default.css';
import { useState } from 'react';
import { Link } from "react-router-dom";
import Modal from './Modal.js'

export default function Imprint() {
  const [show, setShow] = useState(false);

  const handleShow = (value) => {
    setShow(value);
  }

  return (
    <div className='Body'>
      <h1 className='TitleWrapperSites'>My Projekts:</h1>
      <div className='Projects'>
        <Link to='https://modellbahnkaufmann.at' className='Project1'>
            <p className='Title'>Modellbahnkaufmann</p>
            <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
        </Link>
        <Link to='https://play.google.com/store/apps/details?id=com.poschenrieder.rundholzapp&hl=de_AT&gl=US' className='Project2'>
            <p className='Title'>Poschenrieder Rundholzapp</p>
            <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
        </Link>
      </div>
      <Link to='/' className='BackButton'>Back</Link>
      <div className='ContactMe'>
        <i className="fa-solid fa-envelope fa-2xl"  onClick={() => {setShow(true);}}></i>
        {show && <Modal handleShow={handleShow} />}
      </div>
      <div className="Row2">
        <Link to='/imprint' className='SiteLink1'>Imprint</Link>
        <Link to='/privacy' className='SiteLink2'>Privacy</Link>
      </div>
    </div>
  )
}