import './Default.css';
import { useState } from 'react';
import { Link } from "react-router-dom";
import Modal from './Modal.js'

export default function Imprint() {
  const [show, setShow] = useState(false);

  const handleShow = (value) => {
    setShow(value);
  }

  return (
    <div className='Body'>
      <h1>Imprint</h1>
      <h3>Responsible for the content:</h3>
      <p className='Text'>Christian Schwer</p>
      <p className='Text'>office@christianschwer.com</p>
      <p className='Text'>6850 Dornbirn</p>
      <p className='Text'>Austria</p>
      <Link to='/' className='BackButton'>Back</Link>
      <div className='ContactMe'>
        <i className="fa-solid fa-envelope fa-2xl"  onClick={() => {setShow(true);}}></i>
        {show && <Modal handleShow={handleShow} />}
      </div>
    </div>
  )
}