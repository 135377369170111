import { useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import Modal from './Modal.js'

export default function Home() {
  const [show, setShow] = useState(false);

  const handleShow = (value) => {
    setShow(value);
  }

  return (
    <div className="App">
        <div className="Row1">
          <div className="Column1">
            <h1>Hi there, I'm Christian</h1>
          </div>
          <div className="Column2">
            <div className="Profil">
              <img src='Portrait_ohne_Hintergrund.png' alt="It's Me" className="ProfilImage" /> 
            </div>     
          </div>
          <div className="Column3">
            <div className="TitleWrapper">
              <h3>I love to learn new stuff, explore the unknown and grow everyday.</h3>
            </div>
            <div>
              <p className="Text1">You want to know more about me or want to reach out to me?</p>
              <p className="Text1">Contact me: office@christianschwer.com</p>
              <Link className='Link1'
              to={'//www.instagram.com/schwer_christian/'}
              target='_blank'
              aria-label='Instagram'
              >
                <i className='fab fa-instagram'></i>
              </Link>
              <Link className='Link2'
              to={'//github.com/ChristianSchwer'}
              target='_blank'
              aria-label='Github'
              >
                <i className='fab fa-github'></i>
              </Link>
              <h4 className="Text2">My Projects:</h4>
            </div>
            <div className="Row3">
              <Link to='/projects' className="Links1">
              </Link>
              <Link to='/projects' className="Links2">
              </Link>
              {/* <Link to='/projects' className="Links3">
              </Link> */}
            </div>
          </div>
        </div>
        <div className="Row2">
          <Link to='/imprint' className='SiteLink1'>Imprint</Link>
          <Link to='/privacy' className='SiteLink2'>Privacy</Link>
          <Outlet />
        </div>
        <div className='ContactMe'>
          <i className="fa-solid fa-envelope fa-2xl"  onClick={() => {setShow(true);}}></i>
          {show && <Modal handleShow={handleShow} />}
        </div>
      </div>
  )
}
