import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './pages/Home.js';
import Imprint from './pages/Imprint.js';
import Privacy from './pages/Privacy.js';
import Projects from './pages/Projects.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path='/imprint' element={<Imprint />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/projects' element={<Projects />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
